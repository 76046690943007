
import { defineComponent } from 'vue';

import IconUpload from '@white-label-icon/icon-upload';
import { postTempStorageUrl } from '@white-label-helper/api-manage-booking';
import axios from 'axios';

const MIME_TYPES = {
  png: 'image/png',
  jpg: 'image/jpeg',
  jpeg: 'image/jpeg',
};

export default defineComponent({
  name: 'FileUpload',
  components: {
    IconUpload,
  },
  props: {
    urlDescription: {
      type: String,
      default: '',
    },
    storageUrl: {
      type: String,
      required: true,
      default: '',
    },
    uploaderName: {
      type: String,
      required: true,
      default: '',
    },
    formats: {
      type: Array,
      default: () => ['*'],
    },
    createBlob: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    drop: {
      type: Boolean,
      default: true,
    },
    rules: {
      type: Array,
      default() {
        return [];
      },
    },
    preview: {
      type: String,
      default: '',
    },
    displayError: {
      type: Boolean,
      default: false,
    },
    customErrorMessage: {
      type: String,
      default: '',
    },
    uploadContainerClass: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      error: '',
      isDragover: false,
      previewSrc: '',
    };
  },
  computed: {
    supportedFormats() {
      return this.formats
        .map((format) => format.slice(1).toUpperCase())
        .join(', ');
    },
    showError() {
      return this.displayError || this.error.length > 0;
    },
    errorMessage() {
      return this.error || this.customErrorMessage;
    },
  },
  methods: {
    async inputFilter(file) {
      const params = { id: Math.random() };
      const authToken = await this.$auth.getToken();
      if (this.createBlob) {
        params.url = '';
        const URL = window.URL || window.webkitURL;
        if (URL && URL.createObjectURL) {
          params.url = URL.createObjectURL(file);
          params.src = URL.createObjectURL(file);
        }
      }
      this.$emit(
        'uploading',
        Object.assign(file, { progress: 0, error: false }, params)
      );
      const options = {
        signedStorageUrl: this.storageUrl,
        data: {
          prefix: this.urlDescription,
          name: file.name,
        },
        headers: {},
        progress: (progress) =>
          this.$emit(
            'uploading',
            Object.assign(file, { progress, error: false })
          ),
      };
      if (authToken) {
        options.headers = {
          authorization: authToken,
        };
      }
      const mimeType = MIME_TYPES[file.name.split('.').pop().toLowerCase()];
      const { key, url } = await postTempStorageUrl(authToken, {
        prefix: 'passport',
        content_type: mimeType,
        name: file.name,
      });

      const response = await axios.put(url, file, {
        headers: {
          'Content-Type': mimeType,
        },
      });
      this.$emit('uploading', Object.assign(file, { progress: 1, key }));
    },
    processFile(items) {
      let error = '';
      this.$refs.file.value = '';
      this.rules.some((rule) => {
        error = rule(items) || '';
        return !!error;
      });
      this.error = error;
      if (!this.error) {
        items.forEach((file) => this.inputFilter(file));
      } else {
        this.$emit('error');
      }
    },
    handleInputFiles(e) {
      const { target } = e;
      const files = Array.from(target.files);
      const file = files[0];

      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.previewSrc = e.target.result;
        };
        reader.readAsDataURL(file);
      }

      this.processFile(files);
    },
    dropHandler(evt) {
      if (!this.drop) return;
      this.isDragover = false;
      const files = [];
      if (evt.dataTransfer.items) {
        [...evt.dataTransfer.items].forEach((item) => {
          if (item.kind === 'file') {
            const file = item.getAsFile();
            files.push(file);
          }
        });
      } else {
        [...evt.dataTransfer.files].forEach((file) => files.push(file));
      }
      this.processFile(files);
    },
    handleDragover(status) {
      if (!this.drop) {
        this.isDragover = false;
      } else {
        this.isDragover = status;
      }
    },
  },
});
