

// Packages
import { defineComponent } from 'vue';
import { mapActions } from 'pinia';

// Config
import { GTM_URLS_BY_ID } from '@white-label-configuration/constants';

// Helpers
import { DOMAINS_NAMES, getDomainUrl } from '@white-label-helper/switch-app'

// Stores
import { usePreloaderControllerStore } from '@white-label-store/preloader-controller';

export default defineComponent({
  name: 'Error',

  components: {
    ErrorPage: () => import('@white-label-frontend/error'),
  },

  head() {
    return {
      htmlAttrs: {
        lang: this.$i18n.locale,
      },
      script: [
        {
          json: this.$partnerConfig as { [key: string]: any },
          type: 'application/json',
          vmid: 'partner-channel-config',
        },
      ],
      style: [
        {
          cssText: `
            :root {
              --color-brand-100: ${this.$theme.brand[100]};
              --color-brand-16: ${this.$theme.brand[16]};
              --color-brand-24: ${this.$theme.brand[24]};
              --color-brand-50: ${this.$theme.brand[50]};
              --color-brand-8: ${this.$theme.brand[8]};
              --color-cta-background: ${this.$theme.cta.background};
              --color-cta-font: ${this.$theme.cta.font};
              --color-footer-background: ${this.$theme.footer.background};
              --color-header-background: ${this.$theme.header.background};
              --color-header-progress-circles: ${this.$theme.header['progress-circles']};
            }
          `,
        }
      ]
    };
  },

  // Remove preloader for avoid wrong preloader on this page
  created() {
    this.commitUpdatePreloader('');
  },

  mounted() {
    // TODO Need to delete when dev will moved to hosting, which supports Nuxt middleware
    this.browserSupportCheck();

    // load GTM
    const gtmId = this.$partnerConfig ? this.$partnerConfig.gtm_id as string : process.env.NUXT_ENV_GTM_ID as string;
    const scriptURL = GTM_URLS_BY_ID[gtmId];
    this.$bootstrapGTM({ id: gtmId, scriptURL });
  },

  methods: {
    ...mapActions(usePreloaderControllerStore, {
      commitUpdatePreloader: 'updatePreloader',
    }),
    goBack() {
      const domain = getDomainUrl(DOMAINS_NAMES.ECOMMERCE);
      if (domain) {
        window.location.replace(domain);
      }
    },
  },
});
