import { datadogRum } from '@datadog/browser-rum';
import { getAppVariable } from '@white-label-helper/get-app-variable';
import { APP_HERO_PRODUCTS, SCREENS } from '@white-label-configuration/constants';

import type { LDClient, LDContextCommon, LDEvaluationDetail, LDFlagValue, LDMultiKindContext, initialize } from 'launchdarkly-js-client-sdk';
import { getAppHeroProduct } from '@white-label-helper/get-app-hero-product';

let ldClient: {
  initialize: typeof initialize;
  version: string
};

if (process.client) {
  ldClient = require('launchdarkly-js-client-sdk');
} else {
  ldClient = require('launchdarkly-node-client-sdk');
}


// The client
let client: LDClient | undefined = undefined;

/**
 * Create the launch darkly context
 */
export const createLaunchDarklyContext = (): LDMultiKindContext => {
  const organisation: LDContextCommon = {
    kind: 'organisation',
    key: 'organisation',
    channelToken: process.env['NUXT_ENV_AFFILIATE_APPLICATION_TOKEN'],
  }

  const partner: LDContextCommon = {
    kind: 'partner',
    key: 'partner-' +  getAppVariable('partner_id', -1),
    id: getAppVariable('partner_id', -1),
    name: getAppVariable('partner_name', 'missing-partner-name'),
    typeCode: getAppVariable('partner_type_code', 'white_label'),
  }

  const channel: LDContextCommon = {
    kind: 'channel',
    key: process.env['NUXT_ENV_AFFILIATE_APPLICATION_TOKEN'],
    name: 'white label channel',
    token: process.env['NUXT_ENV_AFFILIATE_APPLICATION_TOKEN'],
    productTypesAvailable: getAppVariable('product_types_available', []),
    primaryProductType: getAppHeroProduct(APP_HERO_PRODUCTS.PARKING),
  }

  const service: LDContextCommon = {
    kind: 'service',
    key: 'cavu-white-label',
    name: 'White Label'
  }

  let deviceType;
  if (window.innerWidth < SCREENS.MD) {
    deviceType = 'mobile';
  } else if (SCREENS.MD <= window.innerWidth && window.innerWidth < SCREENS.LG) {
    deviceType = 'tablet';
  } else {
    deviceType = 'desktop';
  }
  const device: LDContextCommon = {
    kind: 'device',
    key: "device",
    deviceType
  }

  return {
    kind: 'multi',
    channel,
    organisation,
    partner,
    service,
    device,
    user: {
      anonymous: true,
    },
  };
}

/**
 * Get the client
 *
 * @returns {LDClient | undefined}
 */
export const getLaunchDarklyClient = () => {
  if (client !== undefined) {
    return client;
  }

  if (process.env['NUXT_ENV_LAUNCH_DARKLY_CLIENT_ID'] === undefined) {
    console.warn('Launch darkly client ID is missing, feature flags will not work');
    return;
  }

  const context = createLaunchDarklyContext()

  client = ldClient.initialize(process.env['NUXT_ENV_LAUNCH_DARKLY_CLIENT_ID'], context, {
    streaming: true,
    inspectors: [{
      type: 'flag-used',
      name: 'dd-inspector',
      method: (key: string, detail: LDEvaluationDetail) => {
        datadogRum.addFeatureFlagEvaluation(key, detail.value);
      }
    }]
  });

  return client
}

/**
 * Get the client
 *
 * @returns {void}
 */
export const closeLaunchDarklyClient = async () => {
  if (client !== undefined) {
    await client.close();
    setLaunchDarklyClient(undefined);
  }
}

/**
 * Set the client
 *
 * @param newClient
 * @returns {void}
 */
export const setLaunchDarklyClient = (newClient: LDClient | undefined) => {
  client = newClient
}

/**
 * Check the value of a feature flag
 *
 * DEVELOPER NOTE:
 * This function implements the falsey by default policy for feature toggles :)
 *
 *
 * @returns {false | any}
 */
export const getFeatureFlag = (flag: string, defaultValue?: LDFlagValue) => {
  const client = getLaunchDarklyClient();

  if (client === undefined) {
    return false;
  }

  return client.variation(flag, defaultValue)
}

type LDCustomEvent = 'view_item_list' | 'view_item' | 'add_to_cart' | 'begin_checkout' | 'booking_completed' | 'purchase_event';

/**
 * Track LaunchDarkly custom events
 *
 * @param flag
 * @param eventName
 * @returns {void}
 */
export const trackLaunchDarklyEvent = (flag: string, eventName: LDCustomEvent): void => {
  const client = getLaunchDarklyClient();

  if (client !== undefined) {
    const isFeatureFlagDefined = client.variation(flag) !== undefined;
    if (isFeatureFlagDefined) {
      client.track(eventName);
    }
  }
}

/**
 * Check if experiment for flag is active
 *
 * @param flag
 * @returns {true | false}
 */
export const isInExperiment = (flag: string): boolean => {
  const client = getLaunchDarklyClient();

  if (client === undefined) {
    return false;
  }

  return client.variationDetail(flag)?.reason?.inExperiment === true;
}

/**
 * Identify the launch darkly client with a new context, whilst merging
 * with the original context.
 * @param newContext The new context to identify
 * @returns {void}
 */
export const identifyWithNewContext = (newContext: LDContextCommon) => {
  const client = getLaunchDarklyClient();

  if (client === undefined) {
    return;
  }

  const launchDarklyContext = {
    ...createLaunchDarklyContext(),
    ...newContext,
  }

  client.identify(launchDarklyContext, undefined, (error) => {
    if (error) {
      console.error('Error identifying with new LD context:', error);
      return;
    }
    console.log('Identified with new LD context:', client.getContext());
  });
}
