
import { defineComponent, PropType } from 'vue';
import type { ModalData } from '@white-label-types/modal';
import { trackError } from '@white-label-helper/ga-tracking';
import { CavuButton, ModalError } from 'ui-shared-components';

export default defineComponent({
  name: 'GlobalModalError',

  components: {
    LibModalError: ModalError,
    CavuButton
  },

  props: {
    modalData: {
      type: Object as PropType<ModalData>,
      required: true,
    },
  },

  computed: {
    isModalButtonsExist(): number | undefined {
      return this.modalData.buttons?.length;
    },

    isOneButton(): boolean | undefined {
      return this.modalData.buttons?.length === 1;
    },

    isCustomButtonType(): boolean {
      return this.modalData.btnType === 'custom';
    },

    prependIcon(): string {
      return this.modalData.prependIcon || null;
    },
  },

  mounted() {
    const errorMessage = this.modalData?.body || this.modalData?.header || '';
    trackError(this.$gtm, errorMessage);
  },
});
