import { DEDICATED_AUTH0_FLOW } from "@white-label-configuration/constants";
import { Route } from "vue-router";

/**
 * Checks the current route's auth0flow matches with the given routeName for the dedicated wl flow.
 * @param route Vue Router
 * @param routeName (Optional) route name to check auth0 flow against, defaults to DEDICATED_AUTH0_FLOW
 * @returns True or false if current route matches against name
 */
export const isDedicatedFlow = (route : Route, routeName: string = DEDICATED_AUTH0_FLOW): boolean => {
  return (route?.meta?.[0]?.auth0Flow === routeName);
};

export const getUrlParam = (route: Route, param: string): string => {
  return route?.query?.[param]?.toString();
};
