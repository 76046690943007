import { AuthObject } from '@white-label-types/auth';
import { datadogRum } from '@datadog/browser-rum';
import { Route } from 'vue-router';
import { isDedicatedFlow } from './dedicated-flow';

interface AuthContext {
    redirect: (path: string) => void;
    $config: Record<string, string>;
    route: Route;
    $auth: AuthObject;
}

const AUTHORISED_ROLES = [
    'cavu-admin',
    'cavu-cs',
    'partner-admin',
    'partner-order-editor'
] as const;

/**
 * Shared auth0 middleware logic for dedicated flows
 * @param context The auth context containing redirect, config, route and auth
 * @param routeName Optional route name to check against (e.g. CHECKOUT)
 */
export const handleDedicatedAuth0 = async ({
    redirect,
    $config,
    route,
    $auth,
}: AuthContext, routeName?: string): Promise<void> => {
    // Check if we're in a dedicated flow for the specified route or any dedicated flow
    if (isDedicatedFlow(route, routeName)) {
        // Check if logged in with correct strategy - partner
        if ($auth.user && $auth.isAuthenticated) {
            const userChannelToken: string = $auth.user['https://user_metadata/channel_token'];
            const appChannelToken: string = $config['NUXT_ENV_AFFILIATE_APPLICATION_TOKEN'];
            const isChannelAuthorised: boolean = !!(userChannelToken && appChannelToken) && (userChannelToken === appChannelToken);

            if (!isChannelAuthorised) {
                datadogRum.addError(
                    new Error('Dedicated flow - User channel token does not match'),
                    {
                        file: 'dedicated-auth.ts helper',
                        userChannelToken,
                        appChannelToken,
                        isChannelAuthorised
                    }
                );
                await $auth.logout();
            }

            // check the user has authorised roles to access dedicated flow
            const userRoles = $auth.user['https://user_metadata/roles'][0];

            const isUserAuthorised = AUTHORISED_ROLES.some(role =>
                userRoles?.includes(role)
            );

            console.log('dedicated-auth', {
                userChannelToken,
                appChannelToken,
                isChannelAuthorised,
                userRoles,
                isUserAuthorised
            });

            if (!isUserAuthorised) {
                // restrict unauthorised users access to non-dedicated WL only
                // redirect and keep logged in
                redirect('/');
            }
        } else {
            // un-authenticated / un-authorised
            redirect('/');
        }
    }
};
